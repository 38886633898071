@import url(https://fonts.googleapis.com/css2?family=Barlow&display=swap);
body {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #080607;
}

a {
  text-decoration: none
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 15px;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 15px;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 15px;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 15px;
}

button {
  outline: none;
}

input {
  color: #080607;
  background-color: #e7eae1;
  font-size:18px;
  padding: 5px;
  display:block;
  width:100%;
  border:none;
  max-width: 300px;
  border-radius: 5px;
}

select {
 padding: 5px;
}

textarea {
  padding: 5px;
}

select:focus { outline:none; }
input:focus { outline:none; }

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: '#080607';
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: '#080607';
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: '#080607';
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #e7eae1;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #e7eae1;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
  height: 9px;
}

/* Track */

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background-color: #e7eae1;
  border-radius: 10px;
}

.eJEChV {
  margin-right: 0 !important;
}

.__active {
  width: 100% !important
}

.carousel .thumb img {
  width: 50px;
  height: 50px;
}
.carousel .thumb {
  width: 50px;
}

.carousel .thumbs-wrapper {
  margin: 20px;
    overflow: hidden;
    position: absolute;
    bottom: -110px;
    margin-top: 0px;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #080607 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #080607 !important;
}

@media screen and (max-width: 40em){
  .responsiveTable tbody tr {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
  }
}
.header-container { 
    height: 100px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.contact-icons {
 width: 30px;   
 height: 30px; 
 margin-right: 5px;
}

.login-register-button {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    margin-left: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 2px;
}

.login-register-button-text {
  font-weight: bold;
  font-size: 11px;
}

.menu-icon{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.home-products-container {
  text-align: center;
}

.home-products-title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 20px;
  position: relative;
}

.home-products-media-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.home-products-content {
  max-width: 500px;
}

.home-products-title {
  margin: 0;
}

.products-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.products-search-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-bottom: 40px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.see-all-products-button {
  cursor: pointer;
}

.see-all-products-button-text {
  margin: 0;
  padding: 9px;
  font-size: 18px;
}
.product-container {
    height: 400px;
    width: 200px;
    position: relative;
    margin: 10px;
}

.offer-icon {
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 1;
    width: 100px
}

.product-image {
    width: 100%;
    height: 200px;
    z-index: 0;
}

.product-title {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
}

.product-description {
    opacity: 0.5;
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
}

.product-title-container {
    max-height: 50px;
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 5px;
}

.product-description-container {
    height: 80px;
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-top: 10px;
}

.product-buttons-container {
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 10px;
}

.product-button-container {
    width: 90px;
    height: 35px;
    margin: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    cursor: pointer;
}

.product-button-text {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    padding: 4px;
}

.cart-icon-styles {
    width: 20px;
    height: 20px;
}
