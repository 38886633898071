.product-container {
    height: 400px;
    width: 200px;
    position: relative;
    margin: 10px;
}

.offer-icon {
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 1;
    width: 100px
}

.product-image {
    width: 100%;
    height: 200px;
    z-index: 0;
}

.product-title {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
}

.product-description {
    opacity: 0.5;
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
}

.product-title-container {
    max-height: 50px;
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 5px;
}

.product-description-container {
    height: 80px;
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-top: 10px;
}

.product-buttons-container {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.product-button-container {
    width: 90px;
    height: 35px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.product-button-text {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    padding: 4px;
}

.cart-icon-styles {
    width: 20px;
    height: 20px;
}