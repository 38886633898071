.header-container { 
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.contact-icons {
 width: 30px;   
 height: 30px; 
 margin-right: 5px;
}

.login-register-button {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
}

.login-register-button-text {
  font-weight: bold;
  font-size: 11px;
}

.menu-icon{
    width: 30px;
    height: 30px;
    cursor: pointer;
}