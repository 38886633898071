.home-products-container {
  text-align: center;
}

.home-products-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  position: relative;
}

.home-products-media-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.home-products-content {
  max-width: 500px;
}

.home-products-title {
  margin: 0;
}

.products-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.products-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 40px;
  flex-wrap: wrap;
}

.see-all-products-button {
  cursor: pointer;
}

.see-all-products-button-text {
  margin: 0;
  padding: 9px;
  font-size: 18px;
}